.form-container {
  background-image: url("../images/form-bg.jpg");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-weight: bold;
  padding: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 20px;
  border-radius: 15px;
}

@media (max-width: 792px) {
  #modal-box {
    width: 250px !important;
  }
}
