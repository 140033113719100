:root {
  --color: antiquewhite;
}

.header {
  background-image: url("../images/header-bg.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -250px;
  min-height: 300px;
}

.header_text {
  text-align: center;
}

.overlay {
  background-color: #304751;
  opacity: 0.8;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_container {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_container h3::before,
.header_container h3::after {
  display: block;
  content: "";
  height: 15px;
  width: 100%;
  margin: 20px 0;
  background: linear-gradient(
    to var(--direction, left),
    var(--color),
    transparent
  );
}

.header_container h3::after {
  --direction: right;
}

@media (max-width: 792px) {
  .header {
    height: auto;
    padding: 20px 0;
  }
  .overlay {
    height: 100%;
  }
  #header_text {
    text-align: left;
  }
}
